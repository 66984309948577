import EventSource from "eventsource";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";
import { ResponseNotificationDto } from "../core/notifications/noticeTypes";
import { getReactAppVersion } from "../auth/auth-api";
import { checkVersion } from "./infrastructureHelpers";
import { postLog } from "../components/ErrorBoundary/backendLogApi";
import { store } from "../store/store";
import { accountsApi } from "../accounts/accountsApi";
import { getPartnersList, getPartnersListType } from "../role/roleSlice";
// window.Buffer = window.Buffer || require("buffer").Buffer;
type EventCallback = (notification: ResponseNotificationDto) => void;

class SseEventManager {
  private eventSource: EventSource | null = null;
  private callbacks: EventCallback[] = [];
  private reconnectAttempt: number = 0;
  private maxReconnectDelay: number = 60000; // Maximum delay of 1 minute
  private apiHealthcheckAttempts: number = 0;
  private maxApiHealthcheckAttempts: number = 3;

  connect(userId: number) {
    if (this.eventSource) {
      this.eventSource.close();
    }

    let token = auth.isAuthenticated().data.accessToken;
    const url = `${CONSTANT.path.host}/sse`;

    this.eventSource = new EventSource(url, {
      headers: {
        "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },

      // heartbeatTimeout: CONSTANT.KEEP_ALIVE_TIMEOUT,
    });

    this.eventSource.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        // console.log("parsedData - ", parsedData);

        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.HEARTBEAT) {
          return;
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.NEW_NOTIFICATION) {
          this.callbacks.forEach((callback) => callback(parsedData.data));
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.ACCOUNT_UPDATED) {
          store.dispatch(accountsApi.util.invalidateTags(["Account"]));
          store.dispatch(getPartnersListType({ signal: null }));
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.SET_ON_MAINTENANCE) {
          if (auth.isAuthenticated().data?.accessToken)
            postLog(
              { level: "error", message: "Redirecting user to maintenance page..." },
              auth.isAuthenticated().data.accessToken
            );
          window.location.href = "/maintenance";
        }
      } catch (error) {
        console.error("Error parsing SSE message:", error);
      }
    };

    this.eventSource.onopen = () => {
      // console.log("SSE connection opened");
      this.reconnectAttempt = 0; // Reset reconnect attempt counter on successful connection
      this.apiHealthcheckAttempts = 0;
    };
    this.eventSource.onerror = (error) => {
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (this.eventSource?.readyState === EventSource.CLOSED) {
        this.eventSource.close();
        this.scheduleReconnect(userId);
      } else {
        // Handle other types of errors
        this.handleError(error);
      }
    };
  }
  private handleError(error: any) {
    // Implement your error handling logic here
    console.error("Handling SSE error:", error);
    // You might want to notify the user, log the error, or take other actions
    getReactAppVersion().then((res) => {
      if (!res?.reactAppVersion || +res?.reactAppVersion === CONSTANT.REACT_APP_VERSION_MAINTENANCE) {
        console.log(`getReactAppVersion attempt ${this.apiHealthcheckAttempts} failed`);
        this.apiHealthcheckAttempts++;
      }
      if (this.apiHealthcheckAttempts > this.maxApiHealthcheckAttempts) {
        const message = `maxApiHealthcheckAttempts ${this.maxApiHealthcheckAttempts} reached, redirecting user to maintenance page`;
        console.log(message);
        postLog({ level: "error", message }, auth.isAuthenticated().data.accessToken);
        this.apiHealthcheckAttempts = 0;
        checkVersion(res?.reactAppVersion);
      }
    });
  }
  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
    this.reconnectAttempt = 0; // Reset reconnect attempt counter on disconnect
  }

  subscribe(callback: EventCallback) {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }

  private scheduleReconnect(userId: number) {
    const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempt), this.maxReconnectDelay);
    console.log(`Attempting to reconnect in ${delay}ms`);

    setTimeout(() => {
      this.reconnectAttempt++;
      this.connect(userId);
    }, delay);
  }
}

export const sseEventManager = new SseEventManager();
